.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.navbar-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.menu-icon {
  font-size: 28px;
  color: #000;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
}

.nav-links a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Dropdown Styles */
.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px 0;
  display: none;
  z-index: 1000;
}

.dropdown-menu li {
  padding: 8px 20px;
}

.dropdown-menu li a {
  color: #000;
  text-decoration: none;
}

.dropdown-menu li a:hover {
  text-decoration: underline;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

/* Icons Section */
.navbar-right .nav-icons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-right .nav-icons .icon {
  font-size: 24px;
  color: #000;
  cursor: pointer;
}

.navbar-right .nav-icons .cart-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.navbar-right .nav-icons .cart-count {
  position: absolute;
  top: -6px;
  right: -10px;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 50%;
}

/* Mobile Styles */
.mobile-nav-links {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  list-style: none;
  padding: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.mobile-nav-links li {
  text-align: center;
}

.mobile-nav-links a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
}

.mobile-nav-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.mobile-nav-icons svg {
  font-size: 24px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .menu-icon {
    display: none;
  }

  .mobile-nav-links {
    display: none;
  }
}

@media (max-width: 650px) {
  .nav-links,
  .icon {
    display: none;
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
}
