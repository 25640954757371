.promo-banner {
  background-color: #38cb89;
  /* color: white; */
  padding: 10px 20px;
  text-align: center;
  position: relative;
  font-size: 16px;
}

.promo-banner .con p {
  margin: 0;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  font-family: "inter";
  margin-left: 10px;
}

.promo-banner .con p a {
  color: #000;
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;

  border-bottom: 1px solid #000;
}

.promo-close-btn {
  background: none;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.promo-banner .promo-close-btn svg {
  margin-bottom: 5px;
}
