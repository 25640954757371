.hero {
  padding: 50px 50px 50px 50px;
  background-color: #000;
  background-image: url("./images/hero.png");
  background-size: cover;
  background-position: center;
  color: white;
}

.hero-img {
  max-width: 500px;
}

.hero-img img {
  width: 100%;
}

.hero-text {
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
}

.hero-text h2 {
  font-size: 72px;
  font-weight: 500;
}

.hero-text p {
  font-size: 20px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.hero-button {
  background-color: #38cb89;
  color: #000;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.hero-button:hover {
  background-color: #ddd;
}

.section {
  padding: 60px 0px;
}

.section .title {
  padding: 20px 0;
}

.section .title h3 {
  font-size: 40px;
}

/* Featured Section */
.featured-items-con {
  display: flex;
  flex-wrap: wrap;
}

.featured-item {
  border: 1px solid #ddd;
  /* padding: 15px; */
  /* border-radius: 8px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.featured-item .badge {
  font-size: 15px;
  font-weight: 700;
}

.featured-item .card-img-con {
  background-color: #f3f5f7;
  padding: 15px;
}

.featured-item img {
  width: 100%;
  height: auto;
  /* border-radius: 8px; */
}

.card-img,
.card-img-top {
  border-radius: 0;
}

.featured-item .card-body {
  background: #fff;
}

.featured-item .card-title {
  color: #141718;
  font-weight: 600;
  font-size: 16px;
  font-family: "inter";
}

.featured-item .card-text {
  color: #121212;
  font-weight: 600;
  font-size: 14px;
  font-family: "inter";
}

.featured-item .add-to-cart {
  border: none;
  background: #141718;
  box-shadow: 0px 8px 16px 0px #00000009;
  padding: 8px 24px;
  border-radius: 8px;

  font-size: 16px;
  font-weight: 500;
  font-family: "inter";

  width: 100%;
}

.item-details {
  text-align: center;
  margin-top: 10px;
}

.add-to-cart:hover {
  background-color: #2a9c70;
}

.featured-item {
  transition: transform 0.2s ease-in-out;
}

.featured-item:hover {
  transform: scale(1.05);
}

.featured-item .add-to-cart {
  display: none;
}

.featured-item:hover .add-to-cart {
  display: inline-block;
  position: absolute;
  bottom: 32%;
  width: 90%;
}

@media (max-width: 768px) {
  .featured-item:hover .add-to-cart {
    bottom: 25%;
    width: 70%;
  }
}

/* Categories Section */
/* .categories-section .row {
  border: 3px solid green;
} */

/* .categories-section .category {
  border: 3px solid red;
} */

.categories-section .category .img-con {
  background: #f3f5f7;
  padding: 20px;
}

.categories-section .category .img-con img {
  width: 100%;
}

.categories-section .category .name {
  color: #23262f;
  font-size: 20px;
  font-weight: 500;
  padding: 15px 0;
}

/* articles section */
.articles-section .more a {
  /* border: 3px solid red; */
  font-size: 16px;
  font-weight: 500;
  font-family: "inter";
  color: #141718;
  line-height: 28px;
}

/* .articles-section .row {
  border: 3px solid purple;
} */

.articles-section .row .article {
  /* border: 3px solid green; */
  padding-right: 0;
}

.articles-section .article .img-con {
  padding-right: 20px;
  /* border: 3px solid blue; */
}

.articles-section .article .name {
  /* border: 3px solid orange; */
  color: #23262f;
  font-weight: 500;
}

.articles-section .article .name h5 {
  font-size: 20px;
}

.articles-section .article .name a {
  color: #23262f;
  padding: 0;
  font-size: 16px;
  text-decoration: none;
}

/* countdown */
/* .countdown-banner {
  border: 3px solid red;
}

.countdown-banner .countdown-image {
  border: 3px solid gold;
} */

.countdown-banner .countdown-image img {
  width: 100%;
  /* border: 3px solid #fff; */
}

.countdown-banner .countdown-text {
  padding: 6%;
  color: #fefefe;
}

.countdown-banner .countdown-text h6 {
  color: #38cb89;
  font-weight: 700;
  font-size: 16px;
  font-family: "inter";
}

.countdown-banner .countdown-text h2 {
  font-weight: 500;
  font-size: 40px;
}

.countdown-banner .timer .time-box {
  background: #fefefe;
  color: #141718;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 34px;
}

.countdown-banner .timer .time-box h4 {
  margin: 0;
}

.countdown-banner .btn {
  border: none;
  background: #38cb89;
  padding: 6px 40px;

  color: #141718;
  font-weight: 500;
  font-size: 16px;
  font-family: "inter";
}

/* shop collection */
.shop-collection .row .card {
  background: #f3f5f7;
  padding: 20px;
}

.shop-collection .row .card img {
  width: 50%;
}

.shop-collection .card .card-body h5 {
  color: #121212;
  font-weight: 500;
  font-size: 34px;
}

.shop-collection .card .card-body a {
  padding: 0;
  color: #121212;
  font-weight: 500;
  font-size: 16px;
  font-family: "inter";
}

.shop-collection .card {
  border: none;
  overflow: hidden;
}

/* Newsletter Section */
.newsletter-section {
  background: #000;
  color: #fefefe;
  /* padding: 2rem 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50vh;
  background-image: url("./images/newsletter-bg.png");
  background-repeat: no-repeat;
  background-position: center;
}

.newsletter-content h3 {
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 0.5rem;
}

.newsletter-content p {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 1rem;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  gap: 1rem;

  border-bottom: 1px solid #fefefe;
}

.newsletter-section .newsletter-form input {
  padding: 0.8rem;
  font-size: 1rem;
  border: none;
  outline: none;
  background: transparent;
  width: 300px;
}

.newsletter-section .newsletter-form input::placeholder {
  color: #fefefe;
  font-weight: 500;
  font-size: 16px;
  font-family: "inter";
}

.newsletter-button {
  padding: 0.8rem 1.5rem;
  font-weight: 500;
  font-size: 16px;
  color: #fefefe;
  border: none;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

/* Instagram Section */
.instagram-section {
  text-align: center;
  /* padding: 2rem 0; */
}

.instagram-section h4 {
  color: #6c7275;
  font-weight: 700;
  font-size: 16px;
  font-family: "inter";
}

.instagram-section h3 {
  color: #141718;
  font-weight: 500;
  font-size: 40px;
}

.instagram-section p {
  color: #141718;
  font-weight: 400;
  font-size: 20px;
  font-family: "inter";
}

.instagram-section .instagram-handle {
  color: #6c7275;
  font-weight: 500;
  font-size: 20px;
  font-family: "poppins";
}

.instagram-section .instagram-image {
  width: 251.6px;
  height: 240px;
}

.instagram-section .instagram-images img {
  width: 100%;
  height: 100%;
}

/* Footer Section */
.footer-section {
  background: #000;
  color: #fff;
  padding: 2rem 0;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
}

.footer-section .footer-top .footer-branding p {
  color: #fefefe;
  font-weight: 500;
  font-size: 20px;
}

.footer-section .footer-top .footer-socials a svg {
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
}

.footer-section .footer-top .footer-links h5 {
  color: #fefefe;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}

.footer-section .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-section .footer-top .footer-links ul li {
  margin-bottom: 1.2rem;
}

.footer-section .footer-top .footer-links ul li a {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  font-family: "inter";
  text-decoration: none;
}

.footer-section .footer-bottom {
  border-top: 1px solid #6c7275;
  padding-top: 1rem;
  text-align: center;
}

.footer-section .footer-bottom p {
  color: #e8ecef;
  font-weight: 400;
  font-size: 12px;
}

.footer-section .footer-bottom p span a {
  color: #6c7275;
  margin-left: 5px;
  text-decoration: none;
}

.footer-payment-icons img {
  max-width: 40px;
  margin: 0 0.5rem;
}
