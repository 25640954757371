.signup-container {
  padding: 0 !important;
  background-color: #f2f4f6;
}

.image-container {
  display: flex;
  align-items: center;
}

.auth-image {
  max-width: 70%;
}

.form-container {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10% 60px 80px 80px !important;

  text-align: left;
}

.form-container h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.form-container p {
  color: #666;
  font-size: 18px;
  margin-bottom: 20px;
}

.form-container input {
  border: none;
  border-bottom: 1px solid #e8ecef;
}

.form-container input::placeholder {
  color: #6c7275;
  font-weight: 400;
  font-size: 16px;
  font-family: "Inter";
}

.form-container a {
  color: #38cb89;
  font-weight: 600;
  text-decoration: none;
}

.checkbox-container input[type="checkbox"] {
  border: 1px solid #6c7275;
}

.checkbox-container a {
  color: #000;
}

a:hover {
  text-decoration: underline;
}

.password-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
